import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Nav = styled.nav`
  position: fixed;
  left: 0;
  width: 550px;
  height: 100vh;
  padding-top: 150px;
  padding-left: 100px;
  overflow-x: hidden;
  z-index: 1;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: ${props => props.isOpen ? '0' : '-100%'};
    width: 80%;
    height: 100vh;
    background: white;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    transition: left 0.3s ease;
    z-index: 1000;
    padding-top: 60px;
  }
`;

const Overlay = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'block' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 999;
  }
`;

const SidebarLink = styled.a`
  text-decoration: none;
  font-size: 48px;
  color: black;
  line-height: 1.3;
  display: block;
  cursor: pointer;
  position: relative;
  
  &:hover {
    color: #F1BCFF;
  }
  
  .count {
    vertical-align: top;
    font-size: 18px;
    position: absolute;
    margin-left: 8px;
  }
`;

const Sidebar = ({ sections, onSectionClick, isOpen, onClose }) => {
  const getLinkCount = (section) => {
    // Guard against null/undefined section
    if (!section) return 0;

    try {
      // For sections with simple links array
      if (section.links) {
        return section.links.length;
      }

      // For the Companies section
      if (section.title === 'Companies' && section.categories?.Companies) {
        return Object.keys(section.categories.Companies).length;
      }

      // For Creative Work and Publications sections
      if (section.categories) {
        return Object.entries(section.categories).reduce((total, [_, category]) => {
          // Count projects if they exist
          const projectCount = category.projects?.length || 0;
          
          // Count events if they exist
          const eventCount = category.events?.length || 0;
          
          // Count highlights if they exist
          const highlightCount = category.highlights?.length || 0;
          
          return total + projectCount + eventCount + highlightCount;
        }, 0);
      }

      return 0;
    } catch (error) {
      console.error('Error calculating count for section:', section.title, error);
      return 0;
    }
  };

  // Guard against null/undefined sections
  if (!sections) {
    console.warn('No sections provided to Sidebar');
    return null;
  }

  return (
    <>
      <Overlay isOpen={isOpen} onClick={onClose} />
      <Nav isOpen={isOpen}>
        {Object.entries(sections).map(([sectionId, section]) => (
          <SidebarLink 
            key={sectionId}
            onClick={() => onSectionClick?.(sectionId)}
          >
            {section?.title}
            {section?.title !== 'About' && section?.title !== 'Contact' && (
              <span className="count">({getLinkCount(section)})</span>
            )}
          </SidebarLink>
        ))}
      </Nav>
    </>
  );
};

Sidebar.propTypes = {
  sections: PropTypes.object.isRequired,
  onSectionClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Sidebar;