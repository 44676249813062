import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: ${props => props.$isOpen ? 'visible' : 'hidden'};
  opacity: ${props => props.$isOpen ? '1' : '0'};
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
  transition: all 0.3s;
  overflow: auto;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: rgba(235, 157, 255, 1);
  margin: auto;
  padding: 48px;
  border: none;
  width: 80%;
  max-width: 1000px;
  position: relative;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const ModalContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: left;

  @media (max-width: 768px) {
    width: 90%;
    max-height: 90vh;
    margin: 5vh auto;
  }
`;

const ModalTitle = styled.h2`
  font-size: 32px;
  margin-bottom: 24px;
  text-align: center;
`;


const ContactInfo = styled.div`
  margin-top: 32px;
  padding-top: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  
  p {
    margin: 8px 0;
  }
`;

const SpecialLink = styled.a`
  color: #000;
  text-decoration: none;
  font-size: 24px;
  position: relative;
  transition: color 0.3s ease;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
  }

  &:hover {
    color: #F1BCFF;
    &:after {
      transform: scaleX(1);
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease, color 0.2s ease;

  &:hover {
    transform: scale(1.1);
    color: #F1BCFF;
  }
`;

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay $isOpen={isOpen} onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>x</CloseButton>
        <ModalContainer>
          <ModalTitle>Contact Me</ModalTitle>
          
          <h3>How Can I Help?</h3>
          <p>
            Whether you&apos;re looking for a content marketing leader, technical marketing strategist, 
            creative collaborator, or an expert consultant, I&apos;m here to help you achieve your goals. 
            From scaling SaaS solutions to crafting innovative marketing campaigns, I bring expertise, 
            passion, and a unique perspective to every project.
          </p>
          <ContactInfo>
            <p><span>✨ </span><SpecialLink href="http://www.linkedin.com/in/meina-ghafouri">
            Let&apos;s work together</SpecialLink></p>
          </ContactInfo>
        </ModalContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Modal;