import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
   @media (max-width: 768px) {
    html {
      font-size: 14px;
    }
  }
    font-weight: regular;
    font-size: 24px;
    line-height: 1.6;
    font-family: 'Helvetica Neue', sans-serif;
    background-color: #E7E6E4;
    color: #000000;
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  hr {
    display: block;
    margin: 0.5em 0 1.25em;
    border: 0;
    border-top: 0.5px solid #000000;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.2s ease;
  }

  button {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }

  ::selection {
    background: #F1BCFF;
    color: black;
  }
`;

export default GlobalStyle; 