import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Modal from './components/Modal';
// import { MainContent } from './styles/App.styles.js';
import resources from './data/resources.js';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`

`;

const SectionTitle = styled.h2`
  text-transform: uppercase;
  visibility: visible;
`;

const CategoryTitle = styled.h3`
  font-weight: 300;
`;

const StyledHr = styled.hr`
  display: block;
  margin-top: .5em;
  margin-bottom: 1.25em;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-width: .5px;
  color: #000000;
`;

const Section = styled.section`
  scroll-margin-top: 140px; // Adjust this value as needed
`;

const MediaContainer = styled.div`
  margin: 1rem 0;
  max-width: 100%;
  
  img, video {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
`;

const MediaCaption = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.5rem;
`;

const ContactLinks = styled.div.attrs({ className: 'links' })`
  margin-top: 1rem;
  
  a {
    display: inline-block;
    text-decoration: none;
    color: #333;
    margin-right: 1.5rem;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const AboutText = styled.p`
  margin: 0.5rem 0;
  line-height: 1.6;
  font-size: 1.1rem;
  
  &:first-of-type {
    font-weight: 500;
  }
`;

const CompaniesSection = styled.div`

  display: flex;
  overflow-x: hidden;
  min-width: 100%;
  height: 200px;
  position: relative;
  
  &:hover {
    .logo-group {
      animation-play-state: paused;
    }
    .logo-item img {
      filter: grayscale(1) contrast(0) blur(1px);
    }
    .logo-item:hover img {
      filter: none;
      transform: scale(1.05);
    }
  }
`;

const LogoGrid = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
  padding: 0 2rem;
  animation: scroll 30s linear infinite;
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  .logo-item {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    
    a {
      display: flex;
      align-items: center;
    }
    
    img {
      width: auto;
      height: 50px;
      object-fit: contain;
      transition: all 0.3s ease;
    }
  }
`;

const ContentItem = styled.div`
  margin-bottom: 2rem;
  padding: 1.5rem;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease;
  cursor: ${props => props.hasUrl ? 'pointer' : 'default'};

  &:hover {
    transform: ${props => props.hasUrl ? 'translateY(-2px)' : 'none'};
  }
`;

const ContentMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
`;

const ContentType = styled.span`
  font-size: 0.85rem;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  background: #f0f0f0;
  color: #666;
`;

const ContentDate = styled.span`
  font-size: 0.85rem;
  color: #666;
`;

const ContentTitle = styled.h4`
  margin: 0.5rem 0;
  color: #333;
  font-size: 1.2rem;
`;

const AppContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: 'Inter', sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MainContent = styled.main`
  flex: 1;
  padding: 40px;
  margin-left: 250px;
  max-width: 1200px;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px;
    max-width: 100%;
    width: calc(100% - 40px); // Account for padding
    margin-top: 60px; // Space for fixed header
  }
      margin-left: 350px;
  padding: 150px 200px 0 200px;

  a {
    color: #000000;
    text-decoration: none;

    &:visited {
      color: #000000;
    }

    &:hover {
      color: #EB9DFF;
    }
  }
`;

const ContentSection = styled.section`
  background: white;
  border-radius: 12px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
`;
const renderContent = (content) => {
  if (!content) return null;

  return content.map((item, index) => {
    if (item.type === 'image') {
      return (
        <img 
          key={index} 
          src={`./images/${item.src}`} 
          alt={item.alt || ''} 
          style={{
            maxWidth: '100%',
            height: 'auto',
            ...item.style
          }}
        />
      );
    }
    
    if (item.type === 'video') {
      return (
        <video 
          key={index}
          src={`./images/${item.src}`} 
          controls={item.controls !== false}
          autoPlay={item.autoPlay}
          loop={item.loop}
          muted={item.muted}
          style={{
            maxWidth: '100%',
            height: 'auto',
            ...item.style
          }}
        />
      );
    }

    return null;
  });
};

const renderSection = (section) => {
  if (section.categories) {
    return Object.entries(section.categories).map(([categoryId, category]) => (
      <div key={categoryId}>
        <CategoryTitle>{category.title}</CategoryTitle>
        {renderContent(category.content)}
        {/* ... rest of category rendering ... */}
      </div>
    ));
  }

  return renderContent(section.content);
};

const App = () => {
  // 1. First, declare all state
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sections, setSections] = useState({});
  const [activeSection, setActiveSection] = useState(null);

  // 2. Then, declare all functions
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMobileMenuOpen(false);
  };

  const renderMedia = (item) => {
    if (item.images || item.video) {
      return (
        <>
          {/* Render video if it exists */}
          {item.video && (
            <MediaContainer>
              <video 
                controls
                muted
                loop
                autoPlay
                playsInline
              >
                <source src={`./images/${item.video.url}`} type={item.video.type} />
              </video>
              {item.video.caption && <MediaCaption>{item.video.caption}</MediaCaption>}
            </MediaContainer>
          )}
                    {/* Render images if they exist */}
          {item.images && item.images.map((image, idx) => (
            <MediaContainer key={`image-${idx}`}>
              <img 
                src={`./images/${image.url}`}
                alt={image.alt}
                loading="lazy"
                style={image.isGif ? { 
                  WebkitAnimation: 'gif-loop infinite',
                  animation: 'gif-loop infinite'
                } : undefined}
              />
              {image.caption && <MediaCaption>{image.caption}</MediaCaption>}
            </MediaContainer>
          ))}
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobileMenuOpen && !event.target.closest('nav')) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isMobileMenuOpen]);

  return (
    <>
      <Container>
        <Header 
          onOpenModal={handleOpenModal} 
          toggleMobileMenu={toggleMobileMenu}
        />
        <ContentWrapper>
          <Sidebar 
            sections={resources}
            onSectionClick={(key) => {
              handleSectionClick(key);
            }}
            activeSection={activeSection}
          />
          <MainContent>
            {Object.entries(resources || {}).map(([key, section]) => (
              <Section id={key} key={key}>
                <SectionTitle>{section?.title}</SectionTitle>
                {section?.description && Array.isArray(section.description) ? (
                  section.description.map((paragraph, index) => (
                    <AboutText key={index}>{paragraph}</AboutText>
                  ))
                ) : (
                  section?.description && <p>{section.description}</p>
                )}
                
                {section?.categories && Object.entries(section.categories).map(([categoryKey, category]) => 
                  categoryKey === 'Companies' ? (
                    <CompaniesSection key={categoryKey}>
                      <div style={{ display: 'flex' }}>
                        <LogoGrid className="logo-group">
                          {Object.entries(category).map(([subCategoryKey, links]) => (
                            <div key={`logo-${subCategoryKey}`} className="logo-item">
                              <a 
                                href={links.url} 
                                target="_blank" 
                                rel="noopener noreferrer"
                              >
                                <img src={links.logo} alt={subCategoryKey} />
                              </a>
                            </div>
                          ))}
                        </LogoGrid>
                        <LogoGrid className="logo-group" aria-hidden="true">
                          {Object.entries(category).map(([subCategoryKey, links]) => (
                            <div key={`logo-duplicate-${subCategoryKey}`} className="logo-item">
                              <a 
                                href={links.url} 
                                target="_blank" 
                                rel="noopener noreferrer"
                              >
                                <img src={links.logo} alt={subCategoryKey} />
                              </a>
                            </div>
                          ))}
                        </LogoGrid>
                      </div>
                    </CompaniesSection>
                  ) : (
                    <div key={categoryKey}>
                      <CategoryTitle>{categoryKey}</CategoryTitle>
                      <StyledHr />
                      {(category.projects || category.events || category.highlights) && 
                        (category.projects || category.events || category.highlights).map((item, index) => (
                          <ContentItem 
                            key={index} 
                            hasUrl={!!item.url}
                            onClick={() => item.url && window.open(item.url, '_blank', 'noopener noreferrer')}
                          >
                            <ContentMeta>
                              {item.type && <ContentType>{item.type}</ContentType>}
                              {item.date && <ContentDate>{item.date}</ContentDate>}
                            </ContentMeta>
                            <ContentTitle>{item.title}</ContentTitle>
                            <p>{item.description}</p>
                            {item.url && (
                              <a 
                                href={item.url}
                                target="_blank" 
                                rel="noopener noreferrer"
                                style={{ color: '#0066cc', textDecoration: 'none' }}
                              >
                                Read More →
                              </a>
                            )}
                            {renderMedia(item)}
                          </ContentItem>
                        ))
                      }
                    </div>
                  )
                )}
                
                {section?.links && (
                  <ContactLinks>
                    {section.links.map((link, index) => (
                      <p key={index}>✨  <a 
                        href={link.url}
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        {link.title}
                      </a></p>
                    ))}
                  </ContactLinks>
                )}
              </Section>
            ))}
          </MainContent>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default App;
