const resources = {
  about: {
    title: 'About',
    description: [
      "I'm a seasoned content and technical marketing expert with over five years of experience in SaaS and cybersecurity, built on a decade-long foundation in engineering.",
      "I specialize in crafting content strategies that make complex technology accessible and impactful.",
      "My work often blurs the line between storytelling and innovation, balancing technical depth with clarity.",
      "Outside of marketing, my art is deeply intertwined with technology, exploring the intersections of identity, creativity, and computation through mediums like machine learning, immersive media, and experimental interfaces."
    ]

  },

  experience: {
    title: 'Companies',
    categories: {
      'Companies': {
        'Okta': {
          logo: 'https://www.okta.com/sites/default/files/Okta_Logo_BrightBlue_Medium.png',
          caption: 'Content Marketing SME & Senior Technical Marketing Manager',
          url: 'https://www.okta.com'
        },
        'Auth0': {
          logo: 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Logo_de_Auth0.svg',
          caption: 'Technical Marketing Engineer & Solutions Engineer',
          url: 'https://auth0.com'
        },
        'Comcast': {
          logo: 'https://1000logos.net/wp-content/uploads/2016/12/Comcast-Logo-1536x864.png',
          caption: 'Software Engineer',
          url: 'https://www.xfinity.com'
        },
        'Urban Outfitters': {
          logo: 'https://seeklogo.com/images/U/urban-outfitters-logo-E9B99ABE57-seeklogo.com.png',
          caption: 'Software Engineer',
          url: 'https://www.urbanoutfitters.com'
        },
        'Reed Elsevier': {
          logo: 'https://www.relx.com/~/media/Images/R/RELX-Group/logo/logo-v2.png?h=49&iar=0&w=191',
          caption: 'Various Roles',
          url: 'https://www.relx.com'
        },
        'Penn Medicine': {
          logo: 'https://www.pennmedicine.org/Assets/PennMedicine/built/images/assets/logo-lg.svg',
          caption: 'Web Manager',
          url: 'https://www.pennmedicine.org'
        },
        'GlaxoSmithKline': {
          logo: 'https://upload.wikimedia.org/wikipedia/en/a/a6/GSK_logo_2014.svg',
          caption: 'Software Engineer',
          url: 'https://www.gsk.com'
        }
      }
    }
  },


//   websites: {
//     title: 'Web Development',
//     categories: {
//       'Major Projects': {
//         projects: [
//           { title: 'Auth0', description: 'Enhanced and maintained Auth0.com website and Contentful integrations' },
//           { title: 'Comcast', description: 'Implemented full accessibility compliance for the XStream web application' },
//           { title: 'Urban Outfitters & Anthropologie', description: 'Designed web features and supported marketing updates' },
//           { title: 'Penn Medicine', description: 'Created patient tracking portal' }
//         ]
//       }
//     }
//   },



  creative: {
    title: 'Creative Work',
    categories: {
      'Interactive': {
        projects: [
            {
                title: 'Auth0 Beer Tap',
                description: 'An IoT experiment combining device flow authentication with a physical beer tap. Built using Auth0 Device Flow, Raspberry Pi, and Python for seamless user experience.',
                video: {
                  url: 'creative/beer.mp4',
                  type: 'video/mp4',
                  caption: 'Authentication-to-beer prototype'
                }
              },
          { 
            title: 'Soft Gears', 
            description: 'An immersive 3D experience in a sewing studio, blending technology, feminism, and machine labor with user-controlled dynamic visualizations.',
            images: [
              {
                url: 'creative/gears.jpg',
                alt: 'Sewing studio',
                caption: 'Interactive installation in a working sewing studio with real-time projections'
              }
            ],
            video: {
              url: 'creative/gears.mp4',
              type: 'video/mp4',
              alt: 'Navigating layers of randomized, rotating objects rendered with CSS and JavaScript',
              caption: 'Dynamic visualization of interlocking geometric patterns'
            }
          },
          {
            title: 'Obscure Emoji Translator',
            description: 'A machine learning interface reimagining Google Translate, leveraging the Tumblr API and Dango Emoji API to translate Dictionary of Obscure Sorrows definitions into emoji, exploring digital communication.',
            images: [
              {
                url: 'creative/emoji.jpg',
                alt: 'Emoji Translator Interface',
                caption: 'ML-powered emoji translation system'
              },
              {
                url: 'creative/emoji-2.png',
                alt: '',
                caption: 'Output from terminal v1'
              }
            ],
            video: {
              url: 'creative/emoji-demo.mp4',
              type: 'video/mp4',
              alt: 'Demo of emoji translation in action'
            }
          },
          { 
            title: 'Pillbox.0', 
            description: 'An unobtrusive pill-minder prototype built with Arduino IDE and Java. Created in collaboration to remind users of medication schedules through ambient light signals, keeping pharmaceuticals discreetly hidden.',
            images: [
              {
                url: 'creative/pillbox.gif',
                alt: 'Pillbox prototype demonstration',
                caption: 'Ambient light reminder system',
                isGif: true
              }
            ]
          },
          {
            title: 'Search Identities',
            description: 'A geo-localized search result analysis exploring racial constructs and biases in search engine results. By blending the top image results from these distinct search engines, the project highlights cultural perceptions and biases embedded within algorithmic systems.',
            images: [
              {
                url: 'creative/meta-1.png',
                alt: 'Meta Babies interface',
                caption: 'Interactive visualization showing search result patterns across different geographic regions'
              },
              {
                url: 'creative/meta-2.png',
                alt: 'Search result analysis',
                caption: 'Detailed analysis view showing data patterns and algorithmic biases'
              },
              {
                url: 'creative/meta-exhibition.JPG',
                alt: 'Exhibition installation view',
                caption: 'Gallery installation showing multiple screens with live data visualization'
              }
            ]
          }
        ]
      },
      'Sketches': {
        projects: [
          { 
            title: 'Volumetric', 
            description: 'An experimental portrait series using OpenFrameworks and OFx FaceTracker to create otherworldly volumetric portraits. The project explores the intersection of facial recognition technology and abstract digital art, transforming human faces into ethereal 3D forms.',
            images: [
              {
                url: 'creative/volumetric-1.gif',
                alt: 'Volumetric portrait visualization',
                caption: 'Face tracking transformed into abstract 3D forms',
                isGif: true
              },
              {
                url: 'creative/volumetric-2.gif',
                alt: 'Ethereal portrait study',
                caption: 'Real-time face tracking visualization',
                isGif: true
              }
            ]
          },
          { 
            title: 'Melt', 
            description: 'GPS data visualization of Brooklyn Bridge hikes, inspired by Takeshi Murata\'s Melter series. Created using D3.js with two views: an artistic interpretation and accurate GPS coordinate mapping.',
            images: [
              {
                url: 'creative/murata.png',
                alt: 'Melter-inspired GPS visualization',
                caption: 'Artistic interpretation of GPS data'
              }
            ],
            video: {
              url: 'creative/waveforms.mp4',
              type: 'video/mp4',
              alt: 'GPS coordinate mapping animation'
            }
          },
          { 
            title: 'Pointillism', 
            description: 'An experiment in p5.js exploring minimal computer vision with tangible inputs. Converts webcam input into colored dots, with microphone input controlling dot size. The visualization becomes especially dynamic when music is playing in the room.',
            images: [
              {
                url: 'creative/pointilism.gif',
                alt: 'Pointillism visualization with audio reactivity',
                caption: 'Audio-reactive pointillism effect using webcam input',
                isGif: true
              }
            ]
          }
        ]
      }
    }
  },

  content: {
    title: 'Publications',
    categories: {
      'Technical Content': {
        description: 'Technical writing and thought leadership focused on identity, security, and developer experience.',
        highlights: [
          { 
            title: 'Learn CIAM by Example eBook',
            type: 'eBook',
            date: '2024',
            description: 'Demonstrates how Customer Identity and Access Management (CIAM) improves application security and user experience (UX), offering practical solutions, best practices, and insights into common challenges, essential considerations, and potential pitfalls.',
            url: 'https://auth0.com/resources/whitepapers/learn-ciam-by-example'
          },
          { 
            title: 'What is Zero Party Data and Why Does It Matter Now',
            type: 'Blog Post',
            date: '2023',
            description: 'Highlights the growing importance of zero-party data (ZPD)—information consumers willingly share with brands—as a key to enhancing personalization, privacy compliance, and trust, particularly as third-party cookies phase out and data privacy regulations evolve.',
            url: 'https://www.okta.com/blog/2023/09/what-is-zero-party-data-and-why-does-it-matter-now/'
          },
          { 
            title: 'The Future is CIAM: Insights from 200 APJ leaders',
            type: 'Whitepaper',
            date: '2023',
            description: 'Examines how Customer Identity and Access Management (CIAM) enhances digital customer experiences, data privacy, and developer innovation, offering insights and recommendations for business leaders.',
            url: 'https://www.okta.com/sg/resources/whitepaper-the-future-is-ciam/'
          },
          { 
            title: 'Best Practices Guide for SMART on FHIR',
            type: 'Technical Guide',
            date: '2021',
            description: 'Strategies for implementing secure authorization and authentication in healthcare applications, emphasizing interoperability and patient data access.',
            url: 'https://auth0.com/resources/whitepapers/best-practices-guide-for-smart-on-fhir'
          }
        ]
      }
    }
  },
  events: {
    title: 'Events',
    categories: {
      'Corporate': {
        events: [
          { 
            title: 'Oktane \'22', 
            description: 'Developed strategic scripts and crafted impactful presentations for executive leadership.',
            url: 'https://oktadev.events/2022/11/oktane-2022-llDNWEIxP6Lb'
          },
          { 
            title: 'Oktane \'21', 
            description: 'Designed technical resources and built engaging demos tailored for the sales engineering team.',
            url: 'https://www.okta.com/resources/oktane-content/2021/'
          }
        ]
      },
      'Academic': {
        events: [
          { 
            title: 'Global Summer School Lecture', 
            description: 'Redefine public spaces through technology, metaphysics, and accessibility to promote inclusive, decolonized virtual systems.',
            url: 'https://globalschool.iaac.net/gss18_tehran/'
          },
          { 
            title: 'NYU Accessible Coding Workshop', 
            description: 'Taught computational languages to non-sighted students',
            url: 'http://cleezyitp.github.io/ProcessingWorkshop/Workshop_1/'
          },
          { 
            title: 'School for Poetic Computation', 
            description: 'Exploring code, design, and technology through innovative, interdisciplinary art and product design projects.',
            url: 'http://sfpc.io/people/meina-kalayeh/'
          }
        ]
      }
    }
  },

  contact: {
    title: 'Contact',
    links: [
      {
        title: 'LinkedIn',
        url: 'http://www.linkedin.com/in/meina-ghafouri',
        description: 'Let\'s work together.'
      }
    ]
  }
};

export default resources;